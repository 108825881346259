<template>
  <router-view/>
</template>

<script>
export default {
  name: 'index',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
